<template>
  <div id="blogdetail">
    <v-slide-y-transition mode="out-in">
      <v-layout
        column
        :style="windowWidth < 770 ? '' : 'margin-left:25%;margin-right:25%'"
      >
        <!-- ####################### SKELETON LOADERS ####################### -->
        <v-layout column v-if="loading">
          <v-layout>
            <v-spacer></v-spacer>
            <v-skeleton-loader
              class="mx-auto"
              type="card"
              width="100%"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>
          </v-layout>

          <v-layout column>
            <v-spacer></v-spacer>
            <template v-for="n in 5">
              <v-skeleton-loader
                class="mx-auto"
                type="list-item-avatar-two-line"
                width="100%"
                height="60"
                :key="n"
              ></v-skeleton-loader>
              <v-spacer :key="n + 'b'"></v-spacer>
            </template>
            <v-spacer></v-spacer>
          </v-layout>
        </v-layout>

        <!-- ############################################## COMPONENTS ##############################################  -->

        <v-layout column v-else>
          <v-layout column align-center>
            <v-card
              v-if="!blogItem.video"
              style="border-radius:0px"
              color="black"
              :min-width="windowWidth < 770 ? '100%' : '95%'"
              :min-height="windowWidth < 770 ? windowWidth : '350'"
              :max-height="windowWidth < 770 ? windowWidth : '350'"
            >
              <v-img
                :src="
                  blogItem.media.indexOf('http') > -1
                    ? blogItem.media
                    : imageProd + blogItem.id + '.png'
                "
                class="align-end"
                :height="windowWidth < 770 ? windowWidth : '350'"
              >
                <v-layout column style="padding-bottom:20px">
                  <v-sheet tile color="#673AB7" height="30" width="170">
                    <p
                      style="padding-left:10px;padding-right:10px"
                      class="newsTag"
                    >
                      {{ makeDate(blogItem.created) }}
                    </p>
                  </v-sheet>
                </v-layout>
              </v-img>
            </v-card>
            <v-card
              v-else-if="blogItem.video"
              style="border-radius:0px"
              color="black"
              :min-width="windowWidth < 770 ? '100%' : '95%'"
            >
              <template>
                <!-- <div :style="'margin:0 auto; min-width:300px;'">
                   <div style="position: relative; padding-bottom:56.25%; height: 0; overflow: hidden; ">
                     <iframe :src="blogItem.media" style="width: 300px; min-width: 100%; position: absolute; top:0; left: 0; height: 100%; overflow: hidden; " width="100%" frameborder="0" allowfullscreen scrolling="no">
                     </iframe>
                    </div>
                 </div> -->
                <vue-plyr>
                  <div class="plyr__video-embed">
                    <iframe
                      :src="blogItem.video_url"
                      allowfullscreen
                      allowtransparency
                      allow="autoplay"
                    ></iframe>
                  </div>
                </vue-plyr>
              </template>
            </v-card>
            <p style="padding-left:20px;padding-right:20px" class="titleText">
              {{ blogItem.title }}
            </p>
            <p class="newsBy" style="margin-top:-10px">
              By: {{ blogItem.created_by }}
            </p>
          </v-layout>

          <v-layout column pa-2>
            <v-layout column pl-4 pr-4>
              <v-divider></v-divider>

              <v-layout pt-1>
                <v-layout pl-2 pb-2 style="margin-left:-10px">
                  <v-btn :dark="dark" text>
                    <v-icon :dark="dark" left>
                      <!-- v-on="on" -->
                      mdi-eye-outline
                    </v-icon>
                    {{ blogItem.views }}
                  </v-btn>
                  <v-btn :dark="dark" text @click.stop="likeThisItem(blogItem)">
                    <v-icon :dark="dark" left>
                      {{
                        lookupLiked(blogItem)
                          ? "mdi-thumb-up"
                          : "mdi-thumb-up-outline"
                      }}
                    </v-icon>
                    {{ blogItem.likes }}
                  </v-btn>
                  <v-layout pa-2 column>
                    <span v-if="windowWidth > 770" class="caption"
                      >Posted
                      {{
                        timeDifference(Math.round(blogItem.publish / 1000))
                      }}</span
                    >
                  </v-layout>
                </v-layout>

                <v-spacer></v-spacer>
                <ShareNetwork
                  style="margin-top:5px"
                  network="facebook"
                  :url="'https://charged.asia/blog/' + blogItem.slug"
                  :title="blogItem.title"
                  tag="v-btn"
                >
                  <v-btn
                    fab
                    color="#3b5998"
                    x-small
                    elevation="0"
                    style="border-radius:0px;width:25px;height:25px"
                    dark
                    ><v-icon small>mdi-facebook</v-icon></v-btn
                  >
                </ShareNetwork>
                <ShareNetwork
                  style="margin-top:5px"
                  network="twitter"
                  :url="'https://charged.asia/blog/' + blogItem.slug"
                  :title="blogItem.title"
                  tag="v-btn"
                >
                  <v-btn
                    fab
                    color="light-blue"
                    x-small
                    elevation="0"
                    style="border-radius:0px;width:25px;height:25px;margin-left:10px"
                    dark
                    ><v-icon small>mdi-twitter</v-icon></v-btn
                  >
                </ShareNetwork>
                <ShareNetwork
                  style="margin-top:5px"
                  network="whatsapp"
                  :url="'https://charged.asia/blog/' + blogItem.slug"
                  :title="blogItem.title"
                  tag="v-btn"
                >
                  <v-btn
                    fab
                    color="#25D366"
                    x-small
                    elevation="0"
                    style="border-radius:0px;width:25px;height:25px;margin-left:10px"
                    dark
                    ><v-icon small>mdi-whatsapp</v-icon></v-btn
                  >
                </ShareNetwork>
                <ShareNetwork
                  style="margin-top:5px"
                  network="linkedin"
                  :url="'https://charged.asia/blog/' + blogItem.slug"
                  :title="blogItem.title"
                  tag="v-btn"
                >
                  <v-btn
                    fab
                    color="light-blue darken-3"
                    x-small
                    elevation="0"
                    style="border-radius:0px;width:25px;height:25px;margin-left:10px"
                    dark
                    ><v-icon small>mdi-linkedin</v-icon></v-btn
                  >
                </ShareNetwork>
                <v-btn
                  fab
                  color="grey darken-2"
                  x-small
                  elevation="0"
                  v-clipboard="'https://charged.asia/blog/' + blogItem.slug"
                  @success="handleSuccess"
                  @error="handleError"
                  style="border-radius:0px;width:25px;height:25px;margin-left:10px;margin-top:5px"
                  dark
                  ><v-icon small>mdi-link-variant</v-icon></v-btn
                >
              </v-layout>
              <v-divider></v-divider>

              <p class="mt-0"></p>

              <div v-html="blogItem.story"></div>

              <p class="mt-0"></p>
            </v-layout>

            <!-- ######################### BLOG COMMENTING ################################### -->

            <v-layout column>
              <v-subheader style="margin-top:-20px" class="grey--text"
                >{{ now }} - {{ forumComments.length }} Comments</v-subheader
              >
              <v-divider></v-divider>

              <v-list two-line>
                <!--  if parent_id === 0 -->
                <template v-for="(item, index) in forumComments">
                  <v-list-item v-if="item.pid === '0'" :key="index">
                    <v-list-item-avatar>
                      <v-img
                        v-if="item.avatar !== ''"
                        :src="item.avatar"
                      ></v-img>
                      <v-gravatar v-else :email="item.name" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size:14px"
                        class="grey--text"
                        >{{ makeDate(item.created) }} by
                        {{ item.name }}</v-list-item-title
                      >
                      <span>{{ item.comment }}</span>
                    </v-list-item-content>

                    <v-list-item-icon
                      v-if="isLoggedIn"
                      style="margin-bottom:0px"
                    >
                      <v-layout column>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              @click.stop="flagComment(item)"
                              v-on="on"
                              icon
                            >
                              <v-icon>
                                mdi-flag-variant-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span
                            >{{
                              getLanguage === "id"
                                ? "Flag as inappropriate"
                                : "Flag as inappropriate"
                            }}
                          </span>
                        </v-tooltip>

                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              @click.stop="openReplies(index)"
                              color="blue"
                              v-on="on"
                              icon
                              style="margin-top:-5px"
                            >
                              <v-icon>
                                mdi-reply-all
                              </v-icon>
                            </v-btn>
                          </template>
                          <span
                            >{{
                              getLanguage === "id"
                                ? "Balas komentar ini"
                                : "Reply to this comment"
                            }}
                          </span>
                        </v-tooltip>
                      </v-layout>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-layout column :key="index + 'f'" v-if="!item.first">
                    <template v-for="(reply, index2) in item.replies">
                      <v-list-item
                        style="margin-top:-20px;margin-left:35px"
                        :key="index2 + 'r'"
                        v-if="index2 === 0 && item.replies.length > 0"
                      >
                        <v-btn
                          text
                          small
                          color="cyan darken-1"
                          class="font-weight-bold"
                          @click="openReplies(index)"
                        >
                          <v-icon v-if="item.open">mdi-chevron-up</v-icon>
                          <v-icon v-else>mdi-chevron-down</v-icon>
                          {{ item.open ? "Hide" : "View" }}
                          {{ item.replies.length }}
                          {{ item.replies.length == 1 ? "reply" : "replies" }}
                        </v-btn>
                      </v-list-item>

                      <v-list-item
                        :style="
                          parseInt(index2) === 0
                            ? 'margin-top:-20px;margin-left:45px'
                            : 'margin-left:45px'
                        "
                        :key="index2 + 'o'"
                        v-show="item.open"
                      >
                        <v-list-item-avatar size="32">
                          <v-img
                            v-if="reply.avatar !== ''"
                            :src="reply.avatar"
                          ></v-img>
                          <v-gravatar v-else :email="reply.name" />
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            style="font-size:14px"
                            class="grey--text"
                            >{{ makeDate(reply.created) }} by
                            {{ reply.name }}</v-list-item-title
                          >
                          <span>{{ reply.comment }}</span>
                        </v-list-item-content>

                        <v-list-item-icon
                          v-if="isLoggedIn"
                          style="margin-bottom:0px"
                        >
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                @click.stop="flagComment(reply)"
                                v-on="on"
                                icon
                              >
                                <v-icon>
                                  mdi-flag-variant-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span
                              >{{
                                getLanguage === "id"
                                  ? "Flag as inappropriate"
                                  : "Flag as inappropriate"
                              }}
                            </span>
                          </v-tooltip>
                        </v-list-item-icon>
                      </v-list-item>

                      <v-list-item
                        style="margin-left:45px"
                        :key="index2 + 're'"
                        v-show="
                          parseInt(index2) + 1 === item.replies.length &&
                            item.open
                        "
                      >
                        <v-text-field
                          v-if="currentUser"
                          class="my-text-field-1"
                          v-model="item.replyComment"
                          color="#673AB7"
                          outlined
                          :placeholder="
                            forumComments.length > 0
                              ? 'Reply to comment...'
                              : 'Be the first to reply...'
                          "
                          maxlength="200"
                          append-icon="mdi-comment-text-outline"
                          counter
                          :id="item.id"
                          v-on:keyup="replyCommentClicked"
                          @click:append="
                            replyCommentClicked(
                              'click',
                              item.id,
                              item.replyComment
                            )
                          "
                        ></v-text-field>
                      </v-list-item>
                    </template>
                  </v-layout>
                  <v-layout
                    column
                    :key="index + 'fi'"
                    v-if="item.first && item.open"
                  >
                    <v-list-item
                      style="margin-left:45px"
                      :key="index + 'first'"
                    >
                      <v-text-field
                        v-if="currentUser"
                        class="my-text-field-1"
                        v-model="item.replyComment"
                        color="#673AB7"
                        outlined
                        :placeholder="
                          forumComments.length > 0
                            ? 'Reply to comment...'
                            : 'Be the first to reply...'
                        "
                        maxlength="200"
                        append-icon="mdi-comment-text-outline"
                        counter
                        :id="item.id"
                        v-on:keyup="replyCommentClicked"
                        @click:append="
                          replyCommentClicked(
                            'click',
                            item.id,
                            item.replyComment
                          )
                        "
                      ></v-text-field>
                    </v-list-item>
                  </v-layout>
                </template>
              </v-list>

              <!-- <v-divider v-if="forumComments.length > 0"></v-divider> -->

              <v-text-field
                v-if="currentUser"
                class="my-text-field-1"
                v-model="comment"
                color="#673AB7"
                outlined
                :placeholder="
                  forumComments.length > 0
                    ? 'Post new comment...'
                    : 'Be the first to post...'
                "
                maxlength="200"
                append-icon="mdi-comment-text-outline"
                counter
                v-on:keyup="postCommentClicked"
                @click:append="postCommentClicked('click')"
              ></v-text-field>

              <v-alert
                v-else-if="!currentUser"
                outlined
                type="info"
                border="top"
              >
                In order to post your comments in this forum,
                {{ blogItem.club_name }} needs to be set as one of your
                Favourite Clubs.<br /><br />Head over to
                <v-btn
                  to="/myaccount"
                  text
                  color="blue"
                  style="margin-top:-3px;padding-left:2px;padding-right:2px;text-transform: none !important"
                  >"{{ lang[getLanguage].MY_ACCOUNT }}"</v-btn
                >
                to pick your Favourite Clubs now!
              </v-alert>

              <v-btn
                to="/signup"
                outlined
                color="deep-purple darken-1"
                v-else
                text
                small
                >{{ lang[getLanguage].BLOG_SIGN_UP }}</v-btn
              >

              <p class="mt-2"></p>
            </v-layout>
          </v-layout>
        </v-layout>

        <!-- ############################################ DIALOGS ################################################# -->

        <v-dialog v-model="flagDialog" persistent max-width="360">
          <v-card>
            <v-card-title class="headline wrap-text">
              {{ lang[getLanguage].FD_FLAG_COMMENT_TXT }}
            </v-card-title>
            <v-card-text>{{ flagItem.comment }}</v-card-text>
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-1" text @click="flagDialog = false">
                Cancel
              </v-btn>
              <v-btn color="green darken-1" outlined @click="processFlagging">
                {{ lang[getLanguage].FD_FLAG_COMMENT }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-slide-y-transition>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
      <v-icon color="green">mdi-check-circle-outline</v-icon>
      <span style="margin-left:5px">{{ snackbarText }}</span>
      <v-btn text color="#388E3C" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbarWarning">
      <v-icon color="amber">mdi-alert-outline</v-icon>
      <span style="padding-left:10px">{{ snackbarText }}</span>
      <v-spacer></v-spacer>
    </v-snackbar>
  </div>
</template>

<script>
import store from "@/store/index";
import { db, auth } from "@/main";
var resizebase64 = require("resize-base64");
const dateformat = require("dateformat");
export default {
  name: "blogdetail",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    blogItem: Object,
  },
  data() {
    return {
      lang: this.$store.state.lang,
      loading: true,
      loadingData: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: "",
      showBottomNav: false,
      signUpDialog: false,
      videoDialog: false,
      flagDialog: false,
      signUpView: 1,
      installMM: false,
      carousel: 0,
      count: 0,
      likeArr: [],
      flagItem: [],
      now: dateformat(new Date().getTime(), "dd mmm, yyyy"),
      show: false,
      resultInterval: null,
      forumComments: [],
      comment: "",
      firstReply: true,
      replyComment: "",
      replyKeepOpenId: "",
      imageDev: "http://209.58.172.141:8082/",
      imageProd: "https://charged.asia/blog/",
      valid: false,
      newsTitle: "",
    };
  },
  components: {},
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getUser() {
      return this.$store.state.user;
    },
    isLoggedIn() {
      return this.$store.state.user.isLoggedIn;
    },
    getPlatform() {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform;
    },
    getLikes() {
      return this.$store.state.user.likesBlog;
    },
  },
  beforeDestroy() {
    clearInterval(this.resultInterval);
  },
  destroyed() {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted() {},
  created() {
    store.commit("ShowNav");
    store.commit("ShowNavHome");
    this.currentUser = auth.currentUser;
    this.init();
  },
  methods: {
    init() {
      this.getComments();
    },
    checkForum() {
      this.getComments();
    },
    setLoadingTrue() {
      this.loading = true;
    },
    getComments() {
      this.loading = true;
      console.log("Get forum comments...");
      this.forumComments = [];
      let commentQuery = db
        .collection("blogs")
        .doc(this.blogItem.id)
        .collection("comments")
        .where("status", "==", 2)
        .orderBy("created", "desc");
      this.commentsRef = commentQuery.onSnapshot(
        (querySnapshot) => {
          // console.log(`Received query snapshot of size ${querySnapshot.size}`)
          if (querySnapshot.empty) {
            console.log("No matching documents.");
            this.loadingData = false;
            this.loading = false;
            return;
          }
          this.forumComments = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, '=>', doc.data())
            var obj = doc.data();
            obj.id = doc.id;
            obj.pid = doc.data().pid === undefined ? "0" : doc.data().pid;
            if (obj.pid === "0") {
              obj.replies = [];
              obj.first = true;
              if (this.replyKeepOpenId === obj.id) {
                obj.open = true;
              } else {
                obj.open = false;
              }
            }
            this.forumComments.push(obj);
            if (this.blogItem.club === "") {
              this.loading = false;
            }
          });
          for (var r in this.forumComments) {
            let pid = this.forumComments[r].pid;
            if (this.forumComments[r].pid !== "0") {
              var idx = this.forumComments.findIndex((item) => item.id == pid);
              console.log(idx);
              console.log(this.forumComments[r]);
              this.forumComments[idx].replies.push(this.forumComments[r]);
              this.forumComments[idx].first = false;
            }
          }
          this.loadingData = false;
          this.loading = false;
          console.log(this.forumComments);
          // ...
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
          // this.loadingData = false
        }
      );
    },
    lookupReply(pid) {
      if (pid === "0") {
        return false;
      } else {
        return true;
      }
    },
    openReplies(idx) {
      this.forumComments[idx].open = !this.forumComments[idx].open;
    },
    postCommentClicked(e) {
      // console.log('search')
      // console.log(e)
      if (this.comment === "") return;
      if (e === "click") {
        this.postForumComment();
        return;
      }
      if (e.keyCode === 13 && this.comment !== "") {
        // alert('Enter was pressed')
        this.postForumComment();
        return;
      }
    },
    postForumComment() {
      var resizedAvatar;
      if (this.getUser.avatar !== "") {
        resizedAvatar = resizebase64(this.getUser.avatar, 200, 200);
      } else {
        resizedAvatar = this.getUser.avatar;
      }
      var payload = {
        postkey: this.blogItem.id,
        comment: this.comment,
        name: this.getUser.displayName,
        uid: this.getUser.uid,
        pid: "0",
        avatar: resizedAvatar,
      };
      this.$store
        .dispatch("postForumComment", payload)
        .then(() => {
          // this.$emit('updatedex', type)
          this.comment = "";
          this.snackbarText = "Your Comment has been posted.";
          this.snackbar = true;
          this.comment = "";
          // this.replyKeepOpenId = ''
          this.increaseCommentCount(this.blogItem.id);
        })
        .catch((error) => {
          console.log(error);
          // this.addCollection = false
        });
    },
    replyCommentClicked(e, parentId, reply) {
      console.log(e);
      console.log(parentId);
      if (e === "click") {
        this.replyComment = reply;

        if (this.replyComment === "") return;

        this.replyKeepOpenId = parentId;
        this.replyForumComment(parentId);
        return;
      }
      if (e.keyCode === 13) {
        this.replyComment = e.target.value;

        if (this.replyComment === "") return;

        this.replyKeepOpenId = e.target.id;
        this.replyForumComment(e.target.id);
        return;
      }
    },
    replyForumComment(parentId) {
      var resizedAvatar;
      if (this.getUser.avatar !== "") {
        resizedAvatar = resizebase64(this.getUser.avatar, 200, 200);
      } else {
        resizedAvatar = this.getUser.avatar;
      }
      var payload = {
        postkey: this.blogItem.id,
        comment: this.replyComment,
        name: this.getUser.displayName,
        uid: this.getUser.uid,
        pid: parentId,
        avatar: resizedAvatar,
      };
      this.$store
        .dispatch("postForumComment", payload)
        .then(() => {
          // this.$emit('updatedex', type)
          this.comment = "";
          this.snackbarText = "Your Reply has been posted.";
          this.snackbar = true;
          this.replyComment = "";
          this.increaseCommentCount(this.blogItem.id);
        })
        .catch((error) => {
          console.log(error);
          // this.addCollection = false
        });
    },
    increaseCommentCount(docId) {
      let obj = {
        comment_count: this.blogItem.comment_count + 1,
        comment_last: new Date().getTime(),
      };
      this.blogItem.comment_count += 1;
      console.log("######### increaseCommentCount ############");
      console.log(docId);
      console.log(obj);
      db.collection("blogs")
        .doc(docId)
        .update(obj)
        .then(() => {
          console.log("Fan Forum comment_count in bucket updated");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    flagComment(item) {
      // Flag comment
      this.flagItem = item;
      console.log(this.flagItem);
      console.log(this.blogItem);
      this.flagDialog = true;
    },
    processFlagging() {
      let obj = {
        status: 1,
        flagged: true,
        flagged_by: this.currentUser.uid,
        flagged_date: new Date().getTime(),
      };
      db.collection("blogs")
        .doc(this.blogItem.id)
        .collection("comments")
        .doc(this.flagItem.id)
        .update(obj)
        .then(() => {
          console.log("Comment in bucket updated");
          // Snackbar That confirms
          this.snackbar = true;
          this.snackbarText = "Comment has been flagged and temporary removed!";
          this.flagDialog = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    newsTitleChanged() {
      var slug = this.newsTitle.toLowerCase();
      slug = slug.replaceAll(" ", "-");
      slug = slug.replaceAll("/", "");
      slug = slug.replaceAll("?", "");
      this.newsSlug = slug;
    },
    likeThisItem(item) {
      if (this.getLikes.indexOf(item.id) > -1) {
        this.snackbarText = "You have already liked this item";
        this.snackbarWarning = true;
        return;
      }
      store.commit("SetLikesBlog", item.id);
      this.likeArr.push(item.id);
      let dispatchObj = {
        likes: (item.likes += 1),
      };
      db.collection("blogs")
        .doc(item.id)
        .update(dispatchObj)
        .then(() => {
          // console.log('Item likes in news bucket updated')
          this.snackbarText = "Your like has been recorded";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    lookupLiked(item) {
      // console.log(item)
      return this.getLikes.indexOf(item.id) > -1;
    },
    makeDate(date) {
      return dateformat(new Date(date), "dd mmm, yyyy");
    },
    handleSuccess(e) {
      console.log(e);
      this.snackbarText = "Copied to clipboard.";
      this.snackbar = true;
    },
    handleError(e) {
      console.log(e);
    },
    timeDifference(previous) {
      var current = Math.round(new Date() / 1000);
      console.log(current);
      var msPerMinute = 60;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      var elapsed = current - previous;

      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + " seconds ago";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " minutes ago";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " hours ago";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " days ago";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " months ago";
      } else {
        return Math.round(elapsed / msPerYear) + " years ago";
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
.titleText {
  font-family: "Righteous", cursive;
  font-size: 32px;
}
.newsTag {
  font-family: "Righteous", cursive;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.wrapperDark {
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.wrapperLight {
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.wrap-text {
  word-break: normal;
}
.my-text-field-1 .v-input__append-inner .v-icon {
  color: #673ab7;
}
</style>
