<template>
  <div
    id="blog"
    :style="
      dark
        ? 'background-color: #2a2a2a;height:100%'
        : 'background-color: #FFF;height:100%'
    "
  >
    <v-slide-y-transition mode="out-in">
      <v-layout column pl-2 pr-2>
        <!-- ####################### SKELETON LOADERS ####################### -->
        <v-layout column v-if="loading">
          <p class="mt-0"></p>

          <v-layout column>
            <v-spacer></v-spacer>
            <template v-for="n in 3">
              <v-skeleton-loader
                class="mx-auto"
                type="card"
                width="100%"
                :key="n"
              ></v-skeleton-loader>
              <v-spacer :key="n + 'b'"></v-spacer>
            </template>
            <v-spacer></v-spacer>
          </v-layout>
        </v-layout>

        <!-- ############################################## COMPONENTS ##############################################  -->

        <v-layout column v-else pt-2>
          <!-- v-show="!loadingData" -->

          <v-layout column pa-2>
            <p
              :class="
                dark
                  ? 'blogText font-weight-bold white--text'
                  : 'blogText font-weight-bold'
              "
            >
              Charged Blog
            </p>
            <hr
              :style="
                dark
                  ? 'border: 1px solid #FFF;margin-top:-15px;width:100px'
                  : 'border: 1px solid #000;margin-top:-15px;width:100px'
              "
            />
          </v-layout>

          <v-card
            id="blogTop"
            :dark="dark"
            class="pa-2"
            flat
            v-if="blogList.length !== 0 && !loadingData"
          >
            <v-list two-line>
              <template v-for="(item, index) in blogList">
                <v-card :dark="dark" class="pa-2" :key="index">
                  <v-list-item
                    style="padding-left:5px;padding-right:0px"
                    link
                    @click="openBlogDetail(item)"
                  >
                    <v-img
                      style="border-radius:10px"
                      :src="item.media === '' ? tempImage : item.media"
                      class="white--text align-end"
                      :max-height="windowWidth < 770 ? 80 : 120"
                      :max-width="windowWidth < 770 ? 80 : 120"
                      :min-height="windowWidth < 770 ? 80 : 120"
                      :min-width="windowWidth < 770 ? 80 : 120"
                    ></v-img>

                    <v-list-item-content style="margin-left:10px">
                      <span
                        v-if="windowWidth > 770"
                        style="font-weight:bold;font-size:22px"
                        class="text-truncate"
                        >{{ item.title }}</span
                      >
                      <span v-else style="font-weight:bold;font-size:18px">{{
                        checkTitleLength(item.title)
                      }}</span>
                      <span
                        v-if="windowWidth > 770"
                        style="font-size:18px;margin-top:5px"
                        class="wrap-text"
                      >
                        {{ checkStoryLength(item.story) }}</span
                      >

                      <v-layout pl-2 pb-2 style="margin-left:-10px">
                        <v-btn :dark="dark" text>
                          <v-icon :dark="dark" left>
                            <!-- v-on="on" -->
                            mdi-eye-outline
                          </v-icon>
                          {{ item.views }}
                        </v-btn>
                        <v-btn
                          :dark="dark"
                          text
                          @click.stop="likeThisItem(item)"
                        >
                          <v-icon :dark="dark" left>
                            {{
                              lookupLiked(item)
                                ? "mdi-thumb-up"
                                : "mdi-thumb-up-outline"
                            }}
                          </v-icon>
                          {{ item.likes }}
                        </v-btn>
                        <v-layout pa-2 column>
                          <span v-if="windowWidth > 770" class="caption"
                            >Posted
                            {{
                              timeDifference(Math.round(item.publish / 1000))
                            }}</span
                          >
                        </v-layout>
                      </v-layout>

                      <!-- <span style="font-size:11px" class="wrap-text">{{ lang[getLanguage].FAN_LAST_POST }}: {{ item.comment_last === 0 ? lang[getLanguage].FAN_NO_POSTS_YET : timeDifference((item.comment_last / 1000)) }}</span>
                    <span style="font-size:11px" class="wrap-text">{{ item.comment_count }} {{ lang[getLanguage].FAN_COMMENTS }}</span> -->
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon large color="deep-purple darken-1"
                          >mdi-chevron-right</v-icon
                        >
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
                <p class="mt-0" :key="index + 'a'"></p>
              </template>
            </v-list>

            <v-divider></v-divider>

            <v-layout pt-1>
              <v-spacer></v-spacer>
              <v-btn
                text
                style="font-size:16px;font-weight:bold;text-transform: none !important;margin-right:-10px"
                @click="loadMoreBlog"
              >
                {{ lang[getLanguage].BLOG_SEE_MORE }}
              </v-btn>
            </v-layout>
          </v-card>

          <v-bottom-navigation
            v-if="showBottomNav"
            :input-value="showBottomNav"
            color="teal accent-4"
            background-color="teal lighten-5"
            fixed
          >
            <v-btn to="/news" style="padding-left:20px;padding-right:0px">
              <span>News</span>
              <v-icon>mdi-newspaper-variant-multiple-outline</v-icon>
            </v-btn>

            <v-btn to="/videos" style="padding-right:0px;padding-left:0px">
              <span>Videos</span>
              <v-icon>mdi-play-circle-outline</v-icon>
            </v-btn>

            <v-btn to="/" style="padding-right:0px;padding-left:0px">
              <span>Home</span>
              <v-icon>mdi-soccer</v-icon>
            </v-btn>

            <v-btn to="/matches" style="padding-right:0px;padding-left:0px">
              <span>Matches</span>
              <v-icon>mdi-soccer-field</v-icon>
            </v-btn>

            <v-btn to="/scorecards" style="padding-right:20px;padding-left:0px">
              <span>Scores</span>
              <v-icon>mdi-counter</v-icon>
            </v-btn>
          </v-bottom-navigation>
        </v-layout>

        <!-- ############################################ DIALOGS ################################################# -->

        <v-dialog
          v-model="blogDetailDialog"
          fullscreen
          persistent
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card :dark="dark">
            <v-toolbar
              flat
              :color="dark ? 'grey darken-4' : 'deep-purple lighten-5'"
            >
              <v-btn :dark="dark" icon @click="blogDetailDialogClose">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <v-img
                v-if="!isMobileDevice"
                :src="logoSrc"
                max-width="200"
                max-height="44"
              ></v-img>
              <v-img
                v-if="isMobileDevice"
                :src="logoSrc"
                max-width="150"
                max-height="33"
              ></v-img>
              <v-spacer></v-spacer>
            </v-toolbar>

            <BlogDetail
              ref="blogComp"
              :windowWidth="windowWidth"
              :windowHeight="windowHeight"
              :blogItem="blogItem"
            >
            </BlogDetail>
          </v-card>
        </v-dialog>

        <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
          <v-icon color="green">mdi-check-circle-outline</v-icon>
          <span style="margin-left:5px">{{ snackbarText }}</span>
          <v-btn text color="#388E3C" @click.native="snackbar = false"
            >Close</v-btn
          >
        </v-snackbar>

        <v-snackbar
          :timeout="4000"
          :bottom="'bottom'"
          v-model="snackbarWarning"
        >
          <v-icon color="amber">mdi-alert-outline</v-icon>
          <span style="padding-left:10px">{{ snackbarText }}</span>
          <v-spacer></v-spacer>
        </v-snackbar>
      </v-layout>
    </v-slide-y-transition>
  </div>
</template>

<script>
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import store from "@/store/index";
import { db } from "@/main";
import BlogDetail from "./comp/BlogDetail";
export default {
  name: "blog",
  metaInfo() {
    return {
      title: this.metaTitle,
      meta: [
        { name: "description", content: this.metaDesc },
        { property: "og:title", content: this.metaTitle },
        { property: "og:og:image", content: this.metaImg },
        { property: "og:og:url", content: this.metaUrl },
        { property: "og:site_name", content: "Charged Asia" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
    isMobileDevice: Boolean,
  },
  data() {
    return {
      metaTitle: "Charged Asia Blog - Ride for Good!",
      metaDesc:
        "Welcome to our blog. Follow the latest updates and joing the discussions about Electric Vehicles and everything around them.",
      metaImg: "",
      metaUrl: "",
      lang: this.$store.state.lang,
      loading: true,
      loadingData: false,
      showBottomNav: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: "",
      blogDetailDialog: false,
      logoSrc: "",
      blogList: [],
      blogPhotos: [],
      blogItem: {},
      likeArr: [],
      show: false,
      resultInterval: null,
      countDown: 8,
      toggle: "left",
      limitBlog: 10,
      tempImage: "",
      imageBlog: "https://charged.asia/blog/",
    };
  },
  components: {
    BlogDetail,
  },
  watch: {
    windowWidth() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
    },
  },
  computed: {
    getLanguage() {
      return this.$store.state.user.language;
    },
    getPlatform() {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform;
    },
    getLikes() {
      return this.$store.state.user.likesBlog;
    },
  },
  beforeDestroy() {
    clearInterval(this.resultInterval);
  },
  destroyed() {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted() {},
  created() {
    store.commit("ShowNav");
    store.commit("ShowNavHome");
    this.storage = getStorage();
    console.log(this.getPlatform);
    this.logoSrc = "./img/logo/charged_logo.png";
    this.tempImage = "./img/icons/file-video-icon.png";
    this.init();
  },
  methods: {
    init() {
      if (this.windowWidth < 768) {
        this.showBottomNav = false;
      } else {
        this.showBottomNav = false;
      }
      this.loadBlogs("init");
      // this.loadPhotos()
    },
    loadBlogs(type) {
      console.log("Load Blogs:" + type);
      // this.blogList = []
      this.loadingData = true;
      let today = new Date().getTime();
      let query = db
        .collection("blogs")
        .where("status", "==", 2)
        .where("category", "==", "blog")
        .orderBy("publish", "desc")
        .limit(this.limitBlog);
      // console.log(query)
      query
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            console.log(this.blogList);
            console.log("No matching Forum documents.");
            this.loading = false;
            this.loadingData = false;
            return;
          }
          var count = 0;
          snapshot.forEach((doc) => {
            console.log(doc.id, "=>", doc.data());
            var obj = doc.data();
            obj.id = doc.id;
            obj.readmore = false;
            var inArray =
              this.blogList.findIndex((item) => item.id === obj.id) > -1;
            if (
              today > doc.data().publish &&
              today < doc.data().expire &&
              !inArray
            ) {
              // today > doc.data().publish &&
              this.blogList.push(obj);
              count += 1;
              // var media = doc.data().media
              if (obj.media.indexOf("http") === -1 && obj.media !== "") {
                this.loadPhotos(doc.id);
              }
            }
          });
          console.log(count);
          // Activate when already 10+ blogs >> later 20+
          /* if (count < 10 && type === 'init') {
          this.limit += 10
          this.news = []
          this.loadBlogs('init')
          return
        } */
          // move pinned to top
          this.loading = false;
          this.loadingData = false;
          this.sortItemsPopular();
          console.log(this.blogList);
          var index = this.blogList.findIndex((item) => item.pinned === true);
          // console.log('Pinned index')
          // console.log(index)
          if (index > -1) {
            var obj = this.blogList[index];
            this.blogList.splice(index, 1);
            this.blogList.splice(0, 0, obj);
          }
          // console.log(this.$route.params.view)
          // console.log(this.$route.params.slug)
          if (this.$route.params.slug !== "home") {
            // this.$router.replace('/home/olahbola-esports')
            var idx = this.blogList.findIndex(
              (item) => item.slug === this.$route.params.slug
            );
            this.openBlogDetail(this.blogList[idx]);
          }
        })
        .catch((err) => {
          console.log("Error getting documents", err);
        });
    },
    loadPhotos(docId) {
      if (this.blogPhotos.length > 0) {
        console.log("No reload needed");
        return;
      }
      // Create a reference with an initial file path and name
      var storageRef = ref(this.storage);
      const imagesRef = ref(storageRef, "blogs/" + docId + ".png");
      getDownloadURL(imagesRef)
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'
          console.log(url);
          // find index in blogPhotos by docId and set the media to url
          var idx = this.blogList.findIndex((item) => item.id === docId);
          this.blogList[idx].media = url;

          // This can be downloaded directly:
          /* var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          var blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send(); */

          // Or inserted into an <img> element
          // var img = document.getElementById('myimg');
          // img.setAttribute('src', url);
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
        });

      // var gsReference = storage.refFromURL('gs://charged-test-app.appspot.com/blogs/7lti5WgdRQlHZT5H3oUq.png')
      // console.log(gsReference)

      // Create a reference from an HTTPS URL
      // Note that in the URL, characters are URL escaped!
      // var httpsReference = storage.refFromURL('https://firebasestorage.googleapis.com/v0/b/charged-test-app.appspot.com/o/blogs%2FlMV7UsV6kNQnngUpuq7N.png');
      // console.log(httpsReference)
    },
    loadMoreBlog() {
      // Load more articles to the news
      // same query but skip the current news.length object from the start >> append the new ones
      this.limitBlog += 20;
      this.loadBlogs("more");
    },
    openBlogDetail(item) {
      console.log(item);
      this.$router.replace("/blog/" + item.slug);
      this.blogItem = item;
      this.blogDetailDialog = true;
      this.metaTitle = item.title;
      this.metaDesc = item.title;
      this.metaImg = item.id + ".png";
      this.metaUrl = this.imageBlog + item.slug;
      setTimeout(() => {
        this.$refs.blogComp.checkForum();
        this.$refs.blogComp.setLoadingTrue();
      }, 1000);
      this.countViews(item);
    },
    blogDetailDialogClose() {
      this.$router.replace("/blog/home");
      // this.$refs.blogComp.setLoadingTrue()
      this.blogDetailDialog = false;
    },
    sortItemsPopular() {
      var tempArr = this.blogList;
      tempArr.sort((a, b) => {
        return b.comment_count - a.comment_count; // desc
      });
      // console.log(this.itemsPopular)
    },
    checkTitleLength(title) {
      title = title.replace(/(<([^>]+)>)/gi, "");
      if (title.length < 40) {
        return title;
      } else {
        return title.substr(0, 40) + "...";
      }
    },
    checkStoryLength(story) {
      story = story.replace(/(<([^>]+)>)/gi, "");
      if (story.length < 200) {
        return story;
      } else {
        return story.substr(0, 200) + "...";
      }
    },
    countViews(item) {
      let dispatchObj = {
        views: (item.views += 1),
      };
      db.collection("blogs")
        .doc(item.id)
        .update(dispatchObj)
        .then(() => {
          // console.log('Item views in token bucket updated')
          // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
          // this.snackbar = true
        })
        .catch((error) => {
          console.log(error);
        });
    },
    likeThisItem(item) {
      if (this.getLikes.indexOf(item.id) > -1) {
        this.snackbarText = "You have already liked this item";
        this.snackbarWarning = true;
        return;
      }
      store.commit("SetLikesBlog", item.id);
      this.likeArr.push(item.id);
      let dispatchObj = {
        likes: (item.likes += 1),
      };
      db.collection("blogs")
        .doc(item.id)
        .update(dispatchObj)
        .then(() => {
          // console.log('Item likes in news bucket updated')
          this.snackbarText = "Your like has been recorded";
          this.snackbar = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    lookupLiked(item) {
      // console.log(item)
      return this.getLikes.indexOf(item.id) > -1;
    },
    timeDifference(previous) {
      var current = Math.round(new Date() / 1000);
      console.log(current);
      var msPerMinute = 60;
      var msPerHour = msPerMinute * 60;
      var msPerDay = msPerHour * 24;
      var msPerMonth = msPerDay * 30;
      var msPerYear = msPerDay * 365;
      var elapsed = current - previous;

      if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + " seconds ago";
      } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + " minutes ago";
      } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + " hours ago";
      } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + " days ago";
      } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + " months ago";
      } else {
        return Math.round(elapsed / msPerYear) + " years ago";
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
.blogText {
  font-family: "Montserrat";
  font-size: 24px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.wrapperDark {
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.wrapperLight {
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.wrap-text {
  word-break: normal;
}
</style>
